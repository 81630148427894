import React from "react";
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OrderButton from "../../../components/orderbutton";
import ProductTable from "../../../components/productTable";
import ProductImage from "../../../images/product/outdoor/P6/Product-Summary-P6-Outdoor.jpg";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import P6Showcase from "../../../components/product/outdoor/p6Showcase";
import Content from "../../../content/produk/outdoor/P6/Product-Page.json";
const p6 = ({ siteTitle }) => {
    const headerStyle = {
        textAlign: "center",
        fontSize: "32px",
        fontWeight: "bold",
        marginBottom: "20px",
        paddingBottom: "20px",
        position: "relative",
        color: "#2d405f",
    };
    return (
        <Layout>
            <SEO
              title={Content.seo.title}
              description={Content.seo.description}
              lang={Content.seo.lang}
            />
            <section id={"produk"} className={"section-bg"}>
                <Container>
                    <div className={"section-title"}>
                        <h1 style={headerStyle}>
                            P6 Outdoor LED Display Module
                        </h1>
                        <Row>
                            <Col>
                                <Image
                                    src={ProductImage}
                                    fluid
                                    alt={"Gambar modul"}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col>
                                <h2>Product Summary</h2>
                                <p style={{ textAlign: "justify" }}>
                                    P.6 Outdoor merupakan layar LED penuh warna
                                    dengan kepadatan pixel tinggi yang
                                    dipergunakan di luar ruangan (Outdoor)
                                    sehingga yang cocok digunakan pada Public
                                    Area, Periklanan, Reklame Digital ,Sistem
                                    informasi Pemerintahan, Sistem Informasi
                                    transportasi publik(bandara, stasiun MRT,
                                    pelabuhan,Jalanan,Tol),Stage Outdoor dan
                                    Sangat cocok dipergunakan sebagai media
                                    promosi Sebuah Usaha atau Lembaga dan
                                    berbagai keperluan lainnya. P.6 Outdoor
                                    memiliki kualitas sebagai berikut:
                                </p>
                                <ul style={{ textAlign: "left" }}>
                                    <li>
                                        <i>Brightness System</i> yang lebih
                                        tinggi.
                                    </li>
                                    <li>
                                        Tahan terhadap cuaca dan iklim luar
                                        ruangan.
                                    </li>
                                    <li>
                                        Media yang inovatif, interaktif,
                                        informatif untuk berbagai keperluan.
                                    </li>
                                    <li>Struktur yang kuat dan tahan lama.</li>
                                </ul>
                                <p>
                                    <OrderButton
                                        messageText={Content.messageText}
                                    />
                                </p>
                            </Col>
                            <Col>
                                <div className={"section-title"}>
                                    <h2 className={"text-center"}>
                                        Unit Module
                                    </h2>
                                </div>
                                <ProductTable
                                    tableName={"Unit Module"}
                                    tableData={Content.moduleUnit}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col>
                                <div className={"section-title"}>
                                    <h2 className={"text-center"}>
                                        LED Cabinet Unit
                                    </h2>
                                </div>
                                <ProductTable
                                    tableName={"LED Unit Cabinet"}
                                    tableData={Content.cabinetUnit}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col>
                                <div className={"section-title"}>
                                    <h2 className={"text-center"}>
                                        Contoh Pemasangan P6 Outdoor
                                    </h2>
                                </div>
                                <P6Showcase />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </Layout>
    );
};
p6.propTypes = {
    siteTitle: PropTypes.string,
};

p6.defaultProps = {
    siteTitle: ``,
};
export default p6;
